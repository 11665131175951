import a from '@/utils/axios/axios'

const clip = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    addClip({ commit, rootGetters, dispatch }, payload) {
      var { dont_add_to_videos } = payload
      return new Promise((resolve, reject) => {
        const team_id = rootGetters['user/currentTeamId']

        dispatch('ticks/tagsUsed', payload.tags, { root: true })

        a.post('/clip', {...payload, team_id})
          .then(res => {
            if(dont_add_to_videos) {
              resolve(res)
              return
            }
            commit('videos/addClipToVideo', {
              video_id: res.data.video_id,
              game_id: res.data.game_id,
              clip: res.data
            }, { root: true })

            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    clipById(_, payload) {
      return new Promise((resolve, reject) => {
        a.get(`/clip/${payload}`)
          .then(e => {
            resolve(e)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    exportClip(_, { id, starttime, endtime }) {
      return new Promise((resolve, reject) => {
        a.post(`/clip/${id}/export`, {
          starttime,
          endtime
        })
          .then(e => {
            resolve(e)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    deleteClip({ commit }, id) {
      return new Promise((resolve, reject) => {
        a.delete(`/clip/${id}`)
          .then(res => {
            commit('videos/removeClip', id, { root: true })
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editClipTags({ commit }, { id, tags }) {
      return new Promise((resolve, reject) => {
        a.put(`/clip/${id}/tag`, {tags})
          .then(res => {
            resolve(res.data)
            commit('videos/updateVideoClip', res.data, { root: true })
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {

          })
      })
    },
    editClipStartAndEndtime(_, { id, starttime, endtime }) {
      return new Promise((resolve, reject) => {
        a.put(`/clip/${id}/range`, { starttime, endtime })
          .then(res => {
            resolve(res.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    editClipTitle({ commit }, { id, title }) {
      return new Promise((resolve, reject) => {
        a.put(`/clip/${id}/title`, { title })
          .then(res => {
            resolve(res.data)
            commit('videos/updateVideoClip', res.data, { root: true })
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editVideoMapPoints({ commit }, { clip_id, points }) {
      return new Promise((resolve, reject) => {
        a.put(`/clip/${clip_id}/point`, { points })
          .then(res => {
            resolve(res.data)
            commit('videos/updateVideoClip', res.data, { root: true })
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {

          })
      })
    },
    putClipGraphics(_, { clip_id, graphics }) {
      return new Promise((resolve, reject) => {
        a.put(`/clip/${clip_id}/graphics`, { graphics })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {

          })
      })
    }
  },
  getters: {

  }
}

export default clip