const gamecode = {
  namespaced: true,
  state: () => ({
    tags: [],
    popout: false,
    game_id: null,
    video_id: null
  }),
  mutations: {
    SET_TAGS(state, tags) {
      state.tags = tags
    },
    ADD_TAG(state, tag) {
      state.tags.push(tag)
    },
    REMOVE_TAG(state, tag_id) {
      state.tags = state.tags.filter(t => t.id !== tag_id)
    },
    SET_GAME_ID(state, game_id) {
      state.game_id = game_id
    },
    SET_VIDEO_ID(state, video_id) {
      state.video_id = video_id
    },
    SET_POPOUT(state, popout) {
      state.popout = popout
    }
  },
  actions: {
    tagClicked({ rootGetters, commit, getters, dispatch }, tag) {
      const group = rootGetters['tag_view/group_by_tag_id'](tag.id)
      if(!group) return

      const was_chosen_tag = getters.tags.find(t => t.id === tag.id)

      if( group.action_type === 'action' ) {
        const accTime = rootGetters['player/accurateCurrentTime']
        const duration = rootGetters['player/duration']
        // Action tag clicked
        if(was_chosen_tag) {
          dispatch('createClipByTag', { tag: was_chosen_tag })
          return
        } else {
          let add_tag = {
            ...tag,
            id: tag.id,
            enduring: group.enduring,
            starttime: group.enduring ? accTime : Math.max(0, accTime + group.buffer_start),
            endtime: group.enduring ? undefined : Math.min(duration, accTime + group.buffer_end)
          }

          commit('ADD_TAG', add_tag)
        }
      } else {
        // Metadata tag clicked
        if(!was_chosen_tag) commit('ADD_TAG', tag)
        else commit('REMOVE_TAG', tag.id)
      }
    },
    handleTimeFurthering({ getters, dispatch }, time) {
      if(!getters.tags.length) return
      // Get the action tags that are currently active and have an endtime
      // Take only the first tag and the next firing of this function will take the next tag and so on
      const tag = getters.tags.find(t => !t.enduring && t.endtime && t.endtime < time)
      if(!tag) return

      dispatch('createClipByTag', { tag, use_endtime: true })
    },
    createClipByTag({ rootGetters, getters, dispatch, commit }, { tag, use_endtime = false }) {
      const tag_ids = getters.tags.map(t => t.id)
      const accTime = rootGetters['player/accurateCurrentTime']

      const add_clip = {
        // leaguewide: isAnalysis,
        title: undefined,
        starttime: tag.starttime,
        endtime: use_endtime ? tag.endtime || accTime : accTime,
        video_id: getters.video_id,
        game_id: getters.game_id,
        tags: tag_ids,
        // points: this.handledPoints,
        // map_color: this.latestColorChosen,
        dont_add_to_videos: getters.popout,
        main_tag_id: tag.id
      }

      dispatch('clip/addClip', add_clip, { root: true })
      // Handle removable tags
      // Check all chosen tags type metadata and remove them if not enduring group
      dispatch('checkRemovableTags')
      commit('REMOVE_TAG', tag.id)
    },
    checkRemovableTags({ getters, commit, rootGetters }) {
      const removable_tags = getters.tags.filter(t => {
        const group = rootGetters['tag_view/group_by_tag_id'](t.id)
        return group.action_type !== 'action'
      })

      if(removable_tags.length) {
        removable_tags.forEach(t => commit('REMOVE_TAG', t.id))
      }
    }
  },
  getters: {
    tags: state => state.tags,
    popout: state => state.popout,
    game_id: state => state.game_id,
    video_id: state => state.video_id
  }
}

export default gamecode