<template>
  <v-navigation-drawer
    :value="show_sidebar || !$vuetify.breakpoint.mobile"
    @input="toggleSideBar($event)"
    app
    elevation="3"
    :temporary="$vuetify.breakpoint.mobile"
    :mini-variant="(!show_sidebar || $route.meta.mini) && !$vuetify.breakpoint.mobile"
    abolute
    touchless
    :class="{ 'pt-10': $vuetify.breakpoint.mobile, 'pb-16': $vuetify.breakpoint.mobile }"
  >
    <v-list>
      <v-list-item-group>
        <v-tooltip
          :disabled="(show_sidebar || $route.meta.mini) && !$vuetify.breakpoint.mobile"
          right
          v-for="item in filteredItems"
          :key="item.text"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              :id="item.path + 'side-bar-button'"
              @click="$router.push(item.path)"
            >
              <v-list-item-icon>
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </template>
          {{ item.text }}
        </v-tooltip>
        <v-divider></v-divider>
      </v-list-item-group>
    </v-list>
    <div>
      <div class="mt-3" style="text-align: center;">
        <div>
          <change-team-button v-if="$vuetify.breakpoint.mobile" :shortenMobile="false"></change-team-button>
        </div>
        <div>
          <v-btn class="mt-3" rounded text v-if="$vuetify.breakpoint.mobile" @click="logout(); $router.push('/login')" small>
            <v-icon>mdi-arrow-left</v-icon>
            {{$t('logout')}}
          </v-btn>
        </div>
      </div>
    </div>
    <div :class="{ 'mb-8': $vuetify.breakpoint.mobile }">
      <UploadThreshold
        v-if="$route.path == '/videos'"
      ></UploadThreshold>
      <UpgradeButton></UpgradeButton>
    </div> 
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mdiVideo, mdiFolder, mdiTag, mdiTimer, mdiDotsHexagon, mdiDiamondStone } from "@mdi/js";
import  UpgradeButton from '@/components/navigation/UpgradeButton'
import UploadThreshold from '@/components/video/UploadThreshold'
import ChangeTeamButton from '../team/ChangeTeamButton.vue';

export default {
  props: ['items', 'icons'],
  data: () => ({
  }),
  icons: { mdiVideo, mdiFolder, mdiTag, mdiTimer, mdiDotsHexagon, mdiDiamondStone },
  components: { 
    UpgradeButton, 
    UploadThreshold, 
    ChangeTeamButton 
  },
  methods: {
    ...mapActions('user', [
      'orderProduct',
      'logout'
    ]),
    ...mapMutations([
      'toggleSideBar'
    ])
  },
  computed: {
    isMini() {
      return ( this.show_sidebar || this.$route.meta.mini ) && !this.$vuetify.breakpoint.mobile
    },
    ...mapGetters([
      'show_sidebar'
    ]),
    ...mapGetters('user', [
      'isOrderer',
      'isAdmin',
      'inLeague',
      'currentPlan',
      'currentTeam',
      'timesDisabled',
      'user',
      'isAppleTesters'
    ]),
    filteredItems() {
      let it = this.items

      if(!this.isAdmin && !this.isOrderer) it = it.filter(item => item.path != '/admin/team')
      if(!this.inLeague) it = it.filter(item => item.path != '/games')
      if(this.timesDisabled) it = it.filter(item => item.path != '/times')
      if(!this.$vuetify.breakpoint.mobile) it = it.filter(item => !item.mobile_only)

      return it
    },
    isAppleApp() {
      let standalone = window.navigator.standalone
      let userAgent = window.navigator.userAgent.toLowerCase()
      let safari = /safari/.test(userAgent)
      let ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios) {
        if (!standalone && safari) {
          // Safari
          return false
        } else if (!standalone && !safari) {
          // iOS webview
          return true
        }
      }

      return false
    },
    isAndroidApp() {
      let userAgent = window.navigator.userAgent.toLowerCase()
      let ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios) {
        return false
      } else {
        if (userAgent.includes('wv')) {
          return true
          // Android webview
        } else {
          return false
          // Chrome
        }
      }
    },
    isApp() {
      if(window.localStorage.getItem('tiimi-application')) return window.localStorage.getItem('tiimi-application')

      let standalone = window.navigator.standalone
      let userAgent = window.navigator.userAgent.toLowerCase()
      let safari = /safari/.test(userAgent)
      let ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios) {
        if (!standalone && safari) {
          // Safari
          return false
        } else if (!standalone && !safari) {
          // iOS webview
          return true
        }
      } else {
        if (userAgent.includes('wv')) {
          return true
          // Android webview
        } else {
          return false
          // Chrome
        }
      }

      return false
    }
  },
}
</script>