import a from '@/utils/axios/axios'

const team = {
  namespaced: true,
  state: () => ({
    users: [],
    invites: [],
    joinCodeState: 0, // 1 = disabled, 0 = active
  }),
  mutations: {
    setTeamUsers(state, users) {
      state.users = users
    },
    setTeamInvites(state, invites) {
      state.invites = invites
    },
    removeUser(state, email) {
      let modify = [...state.users]
      state.users = modify.filter(user => user.email != email)
    },
    removeInvite(state, email) {
      let modify = [...state.invites]
      state.invites = modify.filter(user => user.email != email)
    },
    setAdminStatus(state, { email, team_admin }) {
      let modify = [...state.users]
      let index = modify.findIndex(user => user.email == email)
      if(!index) throw new Error('user not found :(')
      modify[index]['team_admin'] = team_admin
      state.users = modify
    },
    SET_JOIN_CODE_STATE(state, payload) {
      state.joinCodeState = payload
    }
  },
  actions: {
    initTeamUsers({ commit }, team_id) {
      return new Promise((resolve, reject) => {
        a.get(`/team/${team_id}/users`)
          .then(e => {
            commit('setTeamUsers', e.data)
            resolve(e.data)
          })
          .catch(e => reject(e))
      })
    },
    initTeamInvites({ commit }, team_id) {
      return new Promise((resolve, reject) => {
        a.get(`/team/${team_id}/invite`)
          .then(e => {
            commit('setTeamInvites', e.data)
            resolve(e.data)
          })
          .catch(e => reject(e))
      })
    },
    createTeam({ dispatch }, team) {
      return new Promise((resolve, reject) => {
        a.post('/team', team)
          .then(res => {
            if(!team.dont_add_user) dispatch('user/initUser', res.data.token, { root: true })
            resolve(res)
          })
          .catch(e => reject(e))
      })
    },
    changeJoinCode({ dispatch }, team_id) {
      return new Promise((resolve, reject) => {
        a.put(`/team/${team_id}/joincode`)
          .then(res => {
            dispatch('user/initUser', res.data.token, { root: true })
            resolve(res.data.code)
          })
          .catch(e => reject(e))
      })
    },
    setJoinCodeState(_, payload) {
      return new Promise((resolve, reject) => {
        a.post(`/team/${payload.team_id}/joincodestate`, {data: {disabledValue: payload.disabledValue}})
        .then(res => {
          resolve(res)
        })
        .catch(e => reject(e))
      })
    },
    getJoinCodeState({commit}, team_id) {
      return new Promise((resolve, reject) => {
        a.get(`/team/${team_id}/joincodestate`)
        .then(res => {
          commit('SET_JOIN_CODE_STATE', res.data.disabled[0].join_code_disabled)
          resolve(res)
        })
        .catch(e => reject(e))
      })
    },
    deleteUser({ commit }, {team_id, email}) {
      return new Promise((resolve, reject) => {
        a.delete(`/team/${team_id}/user/${email}`)
          .then(res => {
            commit('removeUser', email)
            resolve(res.data)
          })
          .catch(e => reject(e))
      })
    },
    deleteInvite({ commit }, {team_id, email}) {
      return new Promise((resolve, reject) => {
        a.delete(`/team/${team_id}/invite/${email}`)
          .then(res => {
            commit('removeInvite', email)
            resolve(res.data)
          })
          .catch(e => reject(e))
      })
    },
    setAdminStatus({ commit }, { team_id, email, team_admin }) {
      return new Promise((resolve, reject) => {
        a.put(`/team/${team_id}/user/${email}`, { team_admin })
          .then(res => {
            commit('setAdminStatus', {email, team_admin})
            resolve(res.data)
          })
          .catch(e => reject(e))
      })
    },
    leaveTeam({ rootGetters, dispatch }) {
      const team_id = rootGetters['user/currentTeamId']
      const user = rootGetters['user/user']
      const email = user.email
      return new Promise((resolve, reject) => {
        a.delete(`/team/userteam/${team_id}/user/${email}`)
          .then(res => {
            dispatch('user/initUser', res.data.token, { root: true })
            resolve(res.data)
          })
          .catch(e => reject(e))
      })
    },
    inviteUsers({ rootGetters }, emails) {
      const team_id = rootGetters['user/currentTeamId']

      return new Promise((resolve, reject) => {
        a.post(`/team/${team_id}/invite`, { emails })
          .then(res => {
            resolve(res.data)
          })
          .catch(e => reject(e))
      })
    }
   },
  getters: {
    users: state => state.users,
    invites: state => state.invites,
    joinCodeState: state => state.joinCodeState,
  }
}

export default team