import a from '@/utils/axios/axios'
import router from '@/router/index.js'
import { v4 as uuidv4 } from 'uuid';

const noti = {
  namespaced: true,
  state: () => ({
    autosave: false,
    autosave_delay: 4,
    adding_map_point_saves_tag: false,
    adding_tag_saves_tag: false,
    ongoing_clips: [],
    tags: [],
    points: [],
    timeline_row_tags: [],
    dragging: false,
    latest_pointer_pos: {x: 0, y: 0},
    popout: false,
    used_tag_ids: [],
    time_by_channel: null
    /* {
      id: uuid,
      starttime: 4,
      will_end: null,
      main_tag: {
        id: 2,
        action_type: 'background' || 'action'
      },
      meta_tags: [{
        tag_id: jeje,
        action_type: 'metadata'
      }]
    }*/
  }),
  mutations: {
    SET_TAGS(state, value) {
      state.tags = value
    },
    SET_POINTS(state, points) {
      state.points = points
    },
    SET_AUTOSAVE(state, autosave) {
      state.autosave = autosave
    },
    SET_AUTOSAVE_DELAY(state, delay) {
      state.autosave_delay = delay
    },
    SET_ADDING_MAP_POINT_SAVES_TAG(state, value) {
      state.adding_map_point_saves_tag = value
    },
    SET_ADDING_TAG_SAVES_TAG(state, value) {
      state.adding_tag_saves_tag = value
    },
    ADD_ONGOING_CLIP(state, clip) {
      state.ongoing_clips = [...state.ongoing_clips].concat(clip)
    },
    REMOVE_ONGOING_CLIP_BY_ID(state, id) {
      state.ongoing_clips = [...state.ongoing_clips].filter(c => c.id != id)
    },
    SET_ONGOING_CLIPS(state, clips) {
      state.ongoing_clips = clips
    },
    SET_ONGOING_CLIP(state, clip) {
      if(!clip || !clip.id) return
      console.log(clip)
      state.ongoing_clips = [...state.ongoing_clips].filter(o => o.id != clip.id)
      state.ongoing_clips = [...state.ongoing_clips].concat(clip)
    },
    SET_DRAGGING(state, val) {
      state.dragging = val
    },
    SET_LATEST_POINTER_POS(state, val) {
      state.latest_pointer_pos = val
    },
    SET_POPOUT(state, val) {
      state.popout = val
    },
    ADD_USED_TAG_IDS(state, val) {
      state.used_tag_ids = [...state.used_tag_ids].concat(val)
    },
    REMOVE_USED_TAG_IDS(state, val) {
      state.used_tag_ids = [...state.used_tag_ids].filter(id => {
        return !val.includes(id)
      })
    },
    SET_TIME_BY_CHANNEL(state, val) {
      state.time_by_channel = val
    }
  },
  actions: {
    handleTimeFurthering({ dispatch, getters, commit }, time) {
      // Check if some ongoing_clip ended, if found, save new clip and remove it from ongoing
      commit('SET_TIME_BY_CHANNEL', time)
      getters.ongoing_clips.forEach(async ongoing => {
        if(ongoing.will_end && ongoing.will_end < time) {
          commit('SET_TAGS', [...getters.tags].filter(t => t.id != ongoing.main_tag.id))
          await dispatch('handleOngoingEnd', ongoing)
        }

        if(ongoing.starttime - 1 > time) {
          commit('REMOVE_ONGOING_CLIP_BY_ID', ongoing.id)
          commit('SET_TAGS', [...getters.tags].filter(t => t.id != ongoing.main_tag.id))
        }
      })
    },
    removeTagSilently({ getters, commit }, tag) {
      // remove onging
      commit('SET_ONGOING_CLIPS', [...getters.ongoing_clips].filter(o => o.main_tag.id != tag.id))
      commit('SET_TAGS', [...getters.tags].filter(o => o.id != tag.id))
      // remove tag
    },
    async handleNewTagsState({ getters, rootGetters, commit, dispatch }) {
      // Ota uudet tagit sisään ja katso miten ne vaikuttavat klippeihin

      // Käy läpi kaikki ongoing_clips
      const tags_with_actions = getters.tags.map(t => {
        const group = rootGetters['tag/tag_groups'].find(g => g.id == t.group_id)
        return {
          ...t,
          action_type: group.action_type,
          buffer_start: group.buffer_start,
          buffer_end: group.buffer_end,
          enduring: group.enduring
        }
      })

      // Tässä kaikki tagit jotka ovat joko actioneita tai backgroundeja, eli luovat klipin
      const handlables = tags_with_actions.filter(tag => tag.action_type != 'metadata')
      // const actions = handlables.filter(tag => tag.action_type == 'action')
      // const backgrounds = handlables.filter(tag => tag.action_type == 'background')

      // If handlables has a tag that is not the main tag of any clip, create new ongoing clip
      const handlables_promises = handlables?.map(t => {
        return new Promise((resolve, reject) => {
          // Check if clip exists and if does do nothing
          const has_clip = getters.ongoing_clips?.find(c => c?.main_tag.id == t.id)
          if(has_clip) {
            resolve()
            return
          }
          dispatch('formOngoingClip', { tag: t })
            .then(e => {
              commit('ADD_ONGOING_CLIP', e)
              resolve()
            })
            .catch(e => {
              reject(e)
            })

          // If not let's add the ongoing clip
        })
      })

      await Promise.all(handlables_promises)

      // If one of main tags is not present on new tags, we need to save a new clip
      getters.ongoing_clips?.forEach(async ongoing => {
        // lets find a tag that matches and do nothing if found
        const tag = getters.tags?.find(t => t.id == ongoing.main_tag.id)
        if(tag) return

        // Else, the clip has ended and we can save it
        // We can also remove all metadata clips afterwards
        dispatch('handleOngoingEnd', ongoing)
      })
    },
    async handleOngoingEnd({ dispatch, commit, rootGetters, getters }, ongoing) {
      const current_time = rootGetters['player/accurateCurrentTime'] || getters.time_by_channel
      if(ongoing.starttime >= current_time) return
      commit('REMOVE_ONGOING_CLIP_BY_ID', ongoing.id)
      const clip = await dispatch('formSaveableClip', ongoing)
      await dispatch('clip/addClip', {...clip, dont_add_to_videos: getters.popout }, { root: true })
    },
    getBackgroundTags({ getters, rootGetters }, ongoing) {
      const tags_with_actions = getters.tags.map(t => {
        const group = rootGetters['tag/tag_groups'].find(g => g.id == t.group_id)

        return {
          ...t,
          action_type: group.action_type,
          buffer_start: group.buffer_start,
          buffer_end: group.buffer_end,
          enduring: group.enduring
        }
      })

      const background = tags_with_actions.filter(tag => tag.action_type == 'background' && tag.group_id != ongoing.main_tag.group_id)

      return background
    },
    tagsUsed({ commit }, tag_ids) {
      if(!tag_ids || !tag_ids.length) return
      commit('ADD_USED_TAG_IDS', tag_ids)

      setTimeout(() => {
        commit('REMOVE_USED_TAG_IDS', tag_ids)
      }, 600)
    },
    getAndRemoveMetaTags({ getters, rootGetters, commit }) {
      const tags_with_actions = getters.tags.map(t => {
        const group = rootGetters['tag/tag_groups'].find(g => g.id == t.group_id)
        return {
          ...t,
          action_type: group.action_type,
          buffer_start: group.buffer_start,
          buffer_end: group.buffer_end,
          enduring: group.enduring
        }
      })

      const metas = tags_with_actions.filter(tag => tag.action_type == 'metadata')

      const new_tags = [...getters.tags].filter(t => {
        const group = rootGetters['tag/tag_groups'].find(g => g.id == t.group_id)
        console.log(t)
        return group.action_type != 'metadata' || t.keep_chosen
      })

      commit('SET_TAGS', new_tags)

      return metas
    },
    async formSaveableClip({ rootGetters, dispatch, getters, commit }, ongoing) {
      const current_time = rootGetters['player/accurateCurrentTime'] || getters.time_by_channel

      const meta_tags = await dispatch('getAndRemoveMetaTags') || []
      const backgroundTags = await dispatch('getBackgroundTags', ongoing) || []
      const tag = ongoing.main_tag
      const own_meta_tags = ongoing.meta_tags

      const tags_with_names = [...meta_tags, ...backgroundTags, tag, ...own_meta_tags]
      const tags = tags_with_names.map(t => t.id)

      const clip = {
        starttime: ongoing.starttime,
        endtime: current_time + (tag.enduring ? 0 : (tag.buffer_end || 0)),
        title: await dispatch('formClipName', tags_with_names),
        video_id: router.currentRoute.params.id,
        tags,
        main_tag_id: ongoing.main_tag.id,
        points: getters.points
      }

      commit('SET_POINTS', [])

      return clip
    },
    formOngoingClip({ rootGetters, getters }, { tag }) {
      const current_time = rootGetters['player/accurateCurrentTime'] || getters.time_by_channel

      return {
        starttime: current_time + tag.buffer_start || 0,
        will_end: tag.enduring || tag.action_type == 'background' ? null : current_time + tag.buffer_end || 0,
        main_tag: tag,
        id: uuidv4(),
        meta_tags: []
      }
    },
    formClipName(_, tags) { // tags w/ action types
      let sorted = tags.sort((a, b) => {
        if(b.action_type == 'metadata') return -1
        if(a.action_type == 'metadata') return 1
        if(a.action_type == 'action') return -1
        if(b.action_type == 'action') return 1
        return -1
      })

      let name = ""
      sorted.forEach(t => {
        name += t.tag_name + ' '
      })

      return name
    },
    postMap({ commit, rootGetters } , map) {
      const teamId = rootGetters['user/currentTeamId']

      return new Promise((resolve, reject) => {
        a.post('/maps', { ...map, team_id: teamId })
          .then(e => {
            resolve(e.data)
            commit('ADD_MAP', e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    dragStart({ commit }, e) {
      commit('SET_DRAGGING', e.item.id)
    },
    dragEnd({ getters, dispatch, commit }) {
      // console.log(e.item.id)
      const tags_elements = document.querySelectorAll('.tag-adder-tag')
      const { x, y } = getters.latest_pointer_pos
      tags_elements.forEach(t => {
        const { top, bottom, left, right } = t.getBoundingClientRect()
        if(x > left && x < right && y > top && y < bottom) {
          dispatch('addMetaTagToTag', { target_tag_id: t.id, meta_tag_id: getters.dragging })
        }
      })

      commit('SET_DRAGGING', null)

      // dispatch('addMetaTagToTag', { target_tag_id: tag_id, meta_tag_id: getters.dragging })
      // commit('SET_DRAGGING', null)
    },
    addMetaTagToTag({ rootGetters, getters, commit }, { target_tag_id, meta_tag_id }) {
      const all_tags = rootGetters['tag/all_tags']

      const meta_tag = all_tags.find(t => t.id == meta_tag_id)
      const ongoing = getters.ongoing_clips.find(o => o.main_tag.id == target_tag_id)
      if(!ongoing) return
      ongoing.meta_tags = ongoing.meta_tags.concat(meta_tag)

      commit('SET_ONGOING_CLIP', ongoing)
    },
    removeMetaTagFromTag({ getters, commit }, { target_tag_id, meta_tag_id }) {
      const ongoing = getters.ongoing_clips.find(o => o.main_tag.id == target_tag_id)
      if(!ongoing) return
      ongoing.meta_tags = ongoing.meta_tags.filter(t => t.id != meta_tag_id)
      commit('SET_ONGOING_CLIP', ongoing)
    },
    setLatestPointerPos({ commit }, { x, y }) {
      commit("SET_LATEST_POINTER_POS", { x, y })
    }
  },
  getters: {
    autosave: state => state.autosave,
    autosave_delay: state => state.autosave_delay,
    adding_map_point_saves_tag: state => state.adding_map_point_saves_tag,
    adding_tag_saves_tag: state => state.adding_tag_saves_tag,
    tags: state => state.tags,
    used_tag_ids: state => state.used_tag_ids,
    ongoing_clips: state => state.ongoing_clips,
    popout: state => state.popout,
    points: state => state.points,
    dragging: state => state.dragging,
    time_by_channel: state => state.time_by_channel,
    tag_was_chosen: (_, getters) => id => {
      return getters.used_tag_ids.includes(id)
    },
    meta_tags_by_tag_id: (_, getters) => id => {
      const ongoing = getters.ongoing_clips?.find(o => o.main_tag.id == id)
      if(!ongoing) return []
      return ongoing.meta_tags || []
    },
    latest_pointer_pos: state => state.latest_pointer_pos,
    timeline_row_tags: (state, getters, rootState, rootGetters) => {
      let tags = rootGetters['videos/videoClips'].map(t => t.tags)
      // let main_tags = getters.ongoing_clips.map(o => o.main_tag)
      // let all_tags = [...tags, ...main_tags]
      let all_tags = tags
      let groups = rootGetters['tag/tag_groups']
      let unique_tags = []
      all_tags.flat().forEach(t => {
        if(unique_tags.map(tag => tag.id).includes(t.id)) return
        else unique_tags.push(t)
      })

      unique_tags = unique_tags.map(u => {
        return {
          ...u,
          ...groups.find(g => g.id == u.group_id || g.id == u.tag_group_id),
          id: u.id,
        }
      })
      const type = rootGetters['timeline/show_tags_type']
      return unique_tags.filter(t => t.action_type == type)
    }
  }
}

export default noti