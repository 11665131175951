
import a from '@/utils/axios/axios'

const auth = {
  namespaced: true,
  state: () => ({
    leagues: [],
    loading_leagues: false,
    games: [],
    loading_games: false,
    teams: [],
    sports: [],
    clubs: [],
    loading_clubs: false,
    team_users: [],
    loading_team_users: false,
    seasons: [],
    loading_add_season: false,  // Tracks loading state for adding a season
    loading_seasons: false,
    loading_players: false,
    league_players: []
  }),
  mutations: {
    SET_LEAGUE_PLAYERS(state, players) {
      state.league_players = players
    },
    SET_LOADING_PLAYERS(state, val) {
      state.loading_players = val
    },
    SET_LEAGUES(state, payload) {
      state.leagues = payload
    },
    SET_LOADING_LEAGUES(state, val) {
      state.loading_leagues = val
    },
    SET_TEAM_USERS(state, payload) {
      state.team_users = payload
    },
    SET_LOADING_TEAM_USERS(state, payload) {
      state.loading_team_users = payload
    },
    SET_GAMES(state, payload) {
      state.games = payload
    },
    SET_LOADING_GAMES(state, val) {
      state.loading_games = val
    },
    SET_SPORTS(state, payload) {
      state.sports = payload
    },
    ADD_GAME(state, game) {
      state.games = [game].concat([...state.games])
    },
    PUT_GAME(state, game) {
      let mod = [...state.games]

      let i = mod.findIndex(g => g.id == game.id)
      mod[i] = game

      state.games = mod
    },
    REMOVE_GAME_BY_ID(state, id) {
      state.games = [...state.games].filter(g => g.id != id)
    },
    SET_LEAGUE_TEAMS(state, teams) {
      state.teams = teams
    },
    UPDATE_LEAGUE(state, league) {
      let mod = [...state.leagues]
      let index = mod.findIndex(l => l.id === league.id)
      mod[index] = {...league}
      state.leagues = mod
    },
    UPDATE_CLUB(state, club) {
      let mod = [...state.clubs]
      let index = mod.findIndex(c => c.id === club.id)
      mod[index] = {...club}
      state.clubs = mod
    },
    ADD_CLUB(state, club) {
      state.clubs = [...state.clubs].concat(club)
    },
    SET_CLUBS(state, clubs) {
      state.clubs = clubs
    },
    SET_LOADING_CLUBS(state, val) {
      state.loading_clubs = val
    },
    UPDATE_USER_TEAM(state, user) {
      let mod = [...state.team_users]
      let index = mod.findIndex(u => u.email === user.email)
      mod[index] = {...user}
      state.team_users = mod
    },
    SET_SEASONS(state, seasons) {
      state.seasons = seasons
    },
    SET_LOADING_SEASONS(state, loading) {
      state.loading_seasons = loading
    },
    ADD_SEASON(state, season) {
      state.seasons = [...state.seasons, season];
    },
    SET_LOADING_ADD_SEASON(state, loading) {
      state.loading_add_season = loading;
    },
    UPDATE_SEASON(state, updatedSeason) {
      const index = state.seasons.findIndex(season => season.id === updatedSeason.id);
      if (index !== -1) {
        state.seasons = [
          ...state.seasons.slice(0, index),  // All seasons before the updated one
          updatedSeason,                     // The updated season
          ...state.seasons.slice(index + 1)   // All seasons after the updated one
        ];
      }
    },
    UPDATE_PLAYER(state, updatedPlayer) {
      const index = state.league_players.findIndex(player => player.id === updatedPlayer.id);
      if (index !== -1) {
        state.league_players = [
          ...state.league_players.slice(0, index),  // All seasons before the updated one
          updatedPlayer,                     // The updated season
          ...state.league_players.slice(index + 1)   // All seasons after the updated one
        ];
      }
    }
  },
  actions: {
    initLeaguePlayers({ commit }, { league_id }) {
      commit('SET_LOADING_PLAYERS', true)

      return new Promise((resolve, reject) => {
        a(`/league/${league_id}/player`)
          .then(e => {
            commit('SET_LEAGUE_PLAYERS', e.data)
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
          .finally(() => {
            commit('SET_LOADING_PLAYERS', false)
          })
      })
    },
    initLeagues({ commit }) {
      commit('SET_LOADING_LEAGUES', true)
      return new Promise((resolve, reject) => {
        a.get(`/league`)
          .then(res => {
            resolve(res)
            commit('SET_LEAGUES', res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('SET_LOADING_LEAGUES', false)
          })
      })
    },
    initTeams({ commit }, league_id) {
      // let teamId = window.localStorage.getItem('tiimio-latest-team')
      // commit('SET_LOADING_TEAMS')
      return new Promise((resolve, reject) => {
        a.get(`/league/${league_id}/teams`)
          .then(res => {
            resolve(res)
            commit('SET_LEAGUE_TEAMS', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    initSports({ commit }) {
      return new Promise((resolve, reject) => {
        a.get(`/admin/sports`)
          .then(res => {
            resolve(res)
            commit('SET_SPORTS', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    initGames({ commit, rootGetters }, { league_id, season_id }) {
      league_id = league_id || rootGetters['user/leagueId']

      commit('SET_LOADING_GAMES', true)
      commit('SET_GAMES', [])

      return new Promise((resolve, reject) => {
        a.get(`/league/${league_id}/game?season_id=${season_id}`)
          .then(res => {
            resolve(res.data)
            commit('SET_GAMES', res.data.games)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('SET_LOADING_GAMES', false)
          })
      })
    },
    initClubs({ commit }, league_id) {
      commit('SET_LOADING_CLUBS', true)
      return new Promise((resolve, reject) => {
        a(`/league/${league_id}/club`)
          .then(e => {
            commit('SET_CLUBS', e.data)
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
          .finally(() => {
            commit('SET_LOADING_CLUBS', false)
          })
      })
    },
    initSeasons({ commit }, league_id) {
      commit('SET_LOADING_SEASONS', true)
      return new Promise((resolve, reject) => {
        a.get(`/league/${league_id}/seasons`)
          .then(res => {
            commit('SET_SEASONS', res.data)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('SET_LOADING_SEASONS', false)
          })
      })
    },
    saveGame({ commit }, game) {
      return new Promise((resolve, reject) => {
        a.post(`/league/${game.league_id}/game`, game)
          .then(res => {
            resolve(res)
            commit('ADD_GAME', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editGame({ commit }, game) {
      return new Promise((resolve, reject) => {
        a.put(`/league/game/${game.id}`, game)
          .then(res => {
            resolve(res)
            commit('PUT_GAME', res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    deleteGame({ commit }, id) {
      return new Promise((resolve, reject) => {
        a.delete(`/league/game/${id}`)
          .then(() => {
            commit('REMOVE_GAME_BY_ID', id)
            resolve('ok')
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    updateLeague({ commit }, { id, league_name, archived, configuration }) {
      return new Promise((resolve, reject) => {
        a.patch(`/league/${id}`, { league_name, archived, configuration })
          .then((e) => {
            commit('UPDATE_LEAGUE', e.data)
            resolve(e.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    updateClub({ commit }, { id, logo_url, small_logo_url, club_name, club_name_short }) {
      return new Promise((resolve, reject) => {
        a.patch(`/league/club/${id}`, { logo_url, small_logo_url, club_name, club_name_short })
          .then((e) => {
            commit('UPDATE_CLUB', e.data)
            resolve(e.data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    addClub({ commit }, { league_id, logo_url, small_logo_url, club_name, club_name_short }) {
      return new Promise((resolve, reject) => {
        a.post(`/league/${league_id}/club`, { logo_url, small_logo_url, club_name, club_name_short })
          .then(e => {
            commit('ADD_CLUB', e.data)
            resolve(e)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    getTeamUsers({ commit }, { id }) {
      commit('SET_LOADING_TEAM_USERS', true)

      a(`/admin/team/${id}/users`)
        .then(e => {
          commit('SET_TEAM_USERS', e.data)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          commit('SET_LOADING_TEAM_USERS', false)
        })
    },
    updateUserTeam({ commit }, { team_id, email, user_team }) {
      return new Promise((resolve, reject) => {
        a.patch(`/admin/team/${team_id}/user/${email}`, user_team)
          .then(e => {
            commit('UPDATE_USER_TEAM', e.data)
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })

    },
    addSeason({ commit }, { starttime, endtime, season_name, season_info, league_id }) {
      commit('SET_LOADING_ADD_SEASON', true);
      
      return new Promise((resolve, reject) => {
        a.post('/season', { starttime, endtime, season_name, season_info, league_id })
          .then(res => {
            commit('ADD_SEASON', res.data);
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            commit('SET_LOADING_ADD_SEASON', false);
          });
      });
    },
    updateSeason({ commit }, { id, season_name, archived }) {
      return new Promise((resolve, reject) => {
        a.patch(`/season/${id}`, { id, season_name, archived })
          .then(res => {
            commit('UPDATE_SEASON', res.data);
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          })
      });
    },
    updatePlayer({ commit }, { id, league_club_id }) {
      return new Promise((resolve, reject) => {
        a.patch(`/admin/player/${id}`, { league_club_id })
          .then(e => {
            commit('UPDATE_PLAYER', e.data)
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    addSeasonState({ getters, commit }, { season_id, state }) {
      return new Promise((resolve, reject) => {
        a.post(`/season/${season_id}/state`, state)
          .then(e => {
            const season = getters.season_by_id(season_id)
            const states = season.states || []
            commit('UPDATE_SEASON', { ...season, states: states.concat(e.data) })
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    getSeasonStates({ getters, commit }, { id }) {
      a(`/season/${id}/states`)
        .then(e => {
          const season = getters.season_by_id(id)
          commit('UPDATE_SEASON', { ...season, states: e.data })
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  getters: {
    league_players: state => state.league_players,
    loading_league_players: state => state.loading_league_players,
    leagues: state => state.leagues,
    loading_leagues: state => state.loading_leagues,
    games: state => state.games,
    loading_games: state => state.loading_games,
    teams: state => state.teams,
    sports: state => state.sports,
    clubs: state => state.clubs,
    loading_clubs: state => state.loading_clubs,
    team_users: state => state.team_users,
    seasons: state => state.seasons,
    season_by_id: state => id => {
      return state.seasons.find(s => s.id === id)
    },
    unarchived_seasons: state => { return state.seasons.filter(s => !s.archived) },
    unarchived_and_current_seasons: (_, getters) => id => {
      let curr = getters.seasons.find(s => s.id == id)
      return curr ? getters.unarchived_seasons.concat(curr) : getters.unarchived_seasons
    },
    loading_seasons: state => state.loading_seasons,
    loading_add_season: state => state.loading_add_season,
    loading_team_users: state => state.loading_team_users,
    leagueById: (state) => (id) => { return state.leagues.find(l => l.id == id)},
    sportById: (state) => (id) => { return state.sports.find(l => l.id == id)},
    unarchived_leagues: state => { return state.leagues.filter(l => !l.archived) },
    league_club_by_id: (_, getters) => id => {
      return getters.clubs?.find(c => c.id === id) || {}
    }
  }
}

export default auth