import a from '@/utils/axios/axios'

const auth = {
  namespaced: true,
  state: () => ({
    tag_groups: [],
    sport_tag_groups: [],
    loading_tags: false,
    showingArchived: false,
  }),
  mutations: {
    setTagGroups(state, groups) {
      state.tag_groups = groups
    },
    setSportTagGroups(state, groups) {
      state.sport_tag_groups = groups
    },
    appendTag(state, {tag, group_id}) {
      const mod = state.tag_groups
      const i = mod.findIndex(group => group.id == group_id)

      mod[i]['tags'] = mod[i]['tags'].concat(tag)

      state.tags = mod
    },
    appendTagGroup(state, group) {
      state.tag_groups = state.tag_groups.concat(group)
    },
    prependTagGroup(state, group) {
      state.tag_groups = [group].concat(state.tag_groups)
    },
    removeTag(state, tag_id) {
      const mod = state.tag_groups

      mod.forEach((group, i) => {
        mod[i]['tags'] = group['tags'].filter(tag => tag.id != tag_id && tag.original_id != tag_id)
      })

      state.tags = mod
    },
    removeGroup(state, {group_id, mirrors}) {
      let mod = state.tag_groups

      mod = mod.filter(group => {
        if(group.id === group_id) return false;
        // const allTagsArchived = group.tags.every(tag => tag.archived === true);
        return !mirrors.some(mirror => mirror.tag_group_id === group.id);
      })

      state.tag_groups = mod
    },
    setGroup(state, group) {
      let mod = [...state.tag_groups]
      let old_group_index = mod.findIndex(t => t.id == group.id)
      mod[old_group_index] = group

      state.tag_groups = mod
    },
    setTagGroupName(state, { id, group_name }) {
      const mod = [...state.tag_groups]

      const i = mod.findIndex(gr => gr.id == id)

      mod[i]['group_name'] == group_name
      state.tag_groups = mod
    },
    setTagGroupShowInFiltering(state, { id, show_in_filtering }) {
      const mod = [...state.tag_groups]

      const i = mod.findIndex(gr => gr.id == id)

      mod[i]['show_in_filtering'] = show_in_filtering
      state.tag_groups = mod
    },
    setTagGroupShowInTagging(state, { id, show_in_tagging }) {
      const mod = [...state.tag_groups]

      const i = mod.findIndex(gr => gr.id == id)

      mod[i]['show_in_tagging'] = show_in_tagging
      state.tag_groups = mod
    },
    setTagGroupOneTagOnly(state, { id, one_tag_only }) {
      const mod = [...state.tag_groups]

      const i = mod.findIndex(gr => gr.id == id)

      mod[i]['one_tag_only'] = one_tag_only
      state.tag_groups = mod
    },
    setTagName(state, { id, tag_name }) {
      const mod = [...state.tag_groups]

      mod.forEach((group, gi) => {
        group.tags.forEach((tag, ti) => {
          if(tag.id == id || tag.original_id == id) {
            mod[gi]['tags'][ti]['tag_name'] = tag_name
          }
        })
      })

      state.tag_groups = mod
    },
    setTag(state, tag) {
      const mod = [...state.tag_groups]

      mod.forEach((group, gi) => {
        group.tags.forEach((ta, ti) => {
          if(ta.id == tag.id || ta.original_id == tag.id) {
            mod[gi]['tags'][ti] = tag
          }
        })
      })

      state.tag_groups = mod
    },
    setTagColor(state, { id, map_color }) {
      const mod = [...state.tag_groups]

      mod.forEach((group, gi) => {
        group.tags.forEach((tag, ti) => {
          if(tag.id == id || tag.original_id == id) {
            mod[gi]['tags'][ti]['map_color'] = map_color
          }
        })
      })

      state.tag_groups = mod
    },
    setTagHotkey(state, { id, hotkey }) {
      const mod = [...state.tag_groups]

      mod.forEach((group, gi) => {
        group.tags.forEach((tag, ti) => {
          if(tag.id == id || tag.original_id == id) {
            mod[gi]['tags'][ti]['hotkey'] = hotkey
          }
        })
      })

      state.tag_groups = mod
    },
    setTagGroup(state, group) {
      const mod = [...state.tag_groups]

      let i = mod.findIndex(g => g.id == group.id)

      if(i >= 0) mod[i] = group

      state.tag_groups = mod
    },
    UPDATE_TAG_ORDER(state, { tags, group_id }) {
      const group_index = state.tag_groups.findIndex(g => g.id == group_id)
      const mod = [...state.tag_groups]

      const group_tags = mod[group_index]['tags']
      let ordered = []
      tags.forEach(tag_id => {
        ordered.push( group_tags.find(t => t.id == tag_id) )
      })

      mod[group_index]['tags'] = ordered

      state.tag_groups = mod
    },
    SET_LOADING_TAGS(state, value) {
      state.loading_tags = value
    },
    SHOW_ARCHIVED(state) {
      state.showingArchived = !state.showingArchived
    }
  },
  actions: {
    initTags({ commit, rootGetters }) {
      commit('SET_LOADING_TAGS', true)
      const team_id = rootGetters['user/currentTeamId']

      return new Promise((resolve, reject) => {
        a.get(`/tag/team/${team_id}`)
          .then(res => {
            commit('setTagGroups', res.data)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('SET_LOADING_TAGS', false)
          })
      })
    },
    initLeagueTags({ commit, rootGetters }, leagueId) {
      commit('SET_LOADING_TAGS', true)

      if(!leagueId) leagueId = rootGetters['user/leagueId']

      return new Promise((resolve, reject) => {
        a.get(`/tag/league/${leagueId}`)
          .then(res => {
            commit('setTagGroups', res.data)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('SET_LOADING_TAGS', false)
          })
      })
    },
    initSportTags({ commit }, sportId) {
      commit('SET_LOADING_TAGS', true)

      return new Promise((resolve, reject) => {
        a.get(`/tag/sport/${sportId}`)
          .then(res => {
            commit('setTagGroups', res.data)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('SET_LOADING_TAGS', false)
          })
      })
    },
    addTag({ commit }, { group_id, tag_name, position, tag_map_color, hotkey, add_to_sport, keep_chosen }) {
      return new Promise((resolve, reject) => {
        a.post(`/tag`, { add_to_sport, group_id, hotkey, tag_name, position, map_color: tag_map_color, keep_chosen })
          .then(res => {
            res.data.forEach(tag => {
              commit('appendTag', {tag, group_id: tag.group_id})
            })
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })

    },
    updateTagOrder({ commit, rootGetters }, {tags, group_id}) {
      const team_id = rootGetters['user/currentTeamId']
      commit('UPDATE_TAG_ORDER', { tags, group_id })
      return new Promise((resolve, reject) => {
        a.put(`/tag/order`, { team_id, tags })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateGroupOrder({ commit, rootGetters }, groups) {
      const team_id = rootGetters['user/currentTeamId']
      commit('setTagGroups', groups)

      return new Promise((resolve, reject) => {
        a.put(`/tag/group/order`, { team_id, groups })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    addTagGroup({ commit }, { group_name, team_id, mirrors, league_id, one_tag_only, sport_id, immutable, buffer_start, buffer_end, action_type, enduring }) {
      return new Promise((resolve, reject) => {
        a.post(`/tag/group`, { group_name, team_id, mirrors, league_id, one_tag_only, sport_id, immutable, buffer_start, buffer_end, action_type, enduring })
          .then(res => {
            commit('prependTagGroup', res.data)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateTagGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/group/whole/${payload.id}`, payload)
          .then(e => {
            resolve(e.data)
            commit('setGroup', e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    showArchived({commit}) {
      commit('SHOW_ARCHIVED')
    },
    archiveTag({ commit }, {tag_id, trueOrFalse}) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/tag/${tag_id}`, { archived: trueOrFalse })
        .then(res => {
          commit('removeTag', tag_id)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    archiveGroup({ dispatch }, { group_id, trueOrFalse}) {
      return new Promise((resolve, reject) => {
        let promises = []

        promises.push(a.put(`/tag/group/archive/${group_id}`, {archived: trueOrFalse}))
        promises.push(a.put(`/tag/group/tags/archive/${group_id}`, {archived: trueOrFalse}))

        Promise.all(promises)
          .then(responses => {
            resolve(responses)
            dispatch('initTags')
            // commit('removeGroup', {group_id: group_id, mirrors: res.data})
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateGroupName({ commit }, { id, group_name }) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/group/${id}/name`, { group_name })
          .then(res => {
            commit('setTagGroupName', { id, group_name })
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateGroupMirrors({ commit }, { id, new_mirror_ids }) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/group/${id}/mirror`, { new_mirror_ids })
          .then(res => {
            commit('setTagGroup', res.data)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateGroupJoins({ commit }, { id, join_ids }) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/group/${id}/join`, { join_ids })
          .then(res => {
            commit('setTagGroup', res.data)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateGroupShowInFiltering({ commit }, { id, show_in_filtering }) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/group/${id}/show_in_filtering`, { show_in_filtering })
          .then(res => {
            commit('setTagGroupShowInFiltering', { id, show_in_filtering })
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateGroupShowInTagging({ commit }, { id, show_in_tagging }) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/group/${id}/show_in_tagging`, { show_in_tagging })
          .then(res => {
            commit('setTagGroupShowInTagging', { id, show_in_tagging })
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateGroupOneTagOnly({ commit }, { id, one_tag_only }) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/group/${id}/one_tag_only`, { one_tag_only })
          .then(res => {
            commit('setTagGroupOneTagOnly', { id, one_tag_only })
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateTagName({ commit }, { id, tag_name }) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/${id}/name`, { tag_name })
          .then(res => {
            commit('setTagName', { id, tag_name })
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateTagColor({ commit }, { id, map_color }) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/${id}/map_color`, { map_color })
          .then(res => {
            commit('setTagColor', { id, map_color })
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateTagHotkey({ commit }, { id, hotkey }) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/${id}/hotkey`, { hotkey })
          .then(res => {
            commit('setTagHotkey', { id, hotkey })
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    updateTag({ commit }, { id, map_color, hotkey, tag_name, keep_chosen }) {
      return new Promise((resolve, reject) => {
        a.put(`/tag/${id}`, { id, map_color, hotkey, tag_name, keep_chosen })
          .then(res => {
            commit('setTag', res.data)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },
  getters: {
    tag_groups: state => state.tag_groups,
    sport_tag_groups: state => state.sport_tag_groups,
    all_tags: (_, getters) => getters.tag_groups.map(t => t.tags).flat(),
    all_tags_with_groupdata: (_, getters) => {
      let all_tags = getters.all_tags
      const groups = getters.tag_groups
      all_tags = all_tags.map(u => {
        return {
          ...u,
          group: groups.find(g => g.id == u.group_id || g.id == u.tag_group_id),
          id: u.id,
        }
      })

      return all_tags
    },
    loading_tags: state => state.loading_tags,
    showingArchived: (state) => state.showingArchived,
    tag_by_id: (_, getters) => id => {
      return getters.all_tags?.find(t => {
        return t.id == id
      })
    },
    group_by_id: (_, getters) => id => {
      return getters.tag_groups?.find(t => {
        return t.id == id
      })
    }
  }
}

export default auth