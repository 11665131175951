import a from '@/utils/axios/axios'

const game = {
  namespaced: true,
  state: () => ({
    game_players: {
      home: [],
      away: []
    },
    saving_players: false
    // { 
    //   "num": 1,
    //   "pos": "2K", 
    //   "picture_url": "https://www.pesistulokset.fi/storage/pelaajakuvat/8097cde963fed16863c15d600079a35b-maxWidth125.png", 
    //   "external_service_id": 9082, 
    //   "player_name": "Mikko Vihriälä", 
    //   "league_game_id": "84e5f21f-5d13-471f-81af-4228334badf3", 
    //   "league_id": 6,
    //   "league_club_id": 24 
    // }
  }),
  mutations: {
    SET_SAVING_PLAYERS(state, val) {
      state.saving_players = val
    },
    ADD_PLAYER(state, { side, player }) {
      const mod = { ...state.game_players }
      mod[side] = mod[side].concat(player)
      state.game_players = mod
    },
    SET_LINEUPS(state, val) {
      state.lineups = val
    },
    SET_SIDE_PLAYERS(state, { side, players }) {
      const mod = { ...state.game_players }
      mod[side] = players
      state.game_players = mod
    },
    SET_GAME_PLAYERS(state, val) {
      state.game_players = val
    },
    UPDATE_PLAYER_FOUND(state, { name, found, side }) {
      let mod = state.game_players[side]
      const player = mod.find(p => p.player_name == name)
      const index = mod.findIndex(p => p.player_name == name)
      if (index !== -1) {
        const newSide = [
          ...mod.slice(0, index),
          { ...player, found },
          ...mod.slice(index + 1)
        ]
        const otherSide = state.game_players[side !== 'home' ? 'home' : 'away']
        state.game_players = {
          home: side === 'home' ? newSide : otherSide,
          away: side === 'away' ? newSide : otherSide
        }
      }
    }
  },
  actions: {
    changeSideOrder({ commit, getters }, { side, players, number_follows_player }) {
      if(number_follows_player) {
        commit('SET_SIDE_PLAYERS', { side, players })
        return
      }
      
      players = players.map((player, i) => {
        return {
          ...player,
          num: getters.game_players[side][i].num
        }
      })

      commit('SET_SIDE_PLAYERS', { side, players })
    },
    addEmpty({ commit }, { side, league_club_id, league_id, league_game_id }) {
      commit('ADD_PLAYER', {
        side,
        player: {
          num: '',
          pos: '',
          player_name: '',
          league_club_id, 
          league_id, 
          league_game_id
        }
      })
    },
    removePlayer({ commit, getters }, { side, index }) {
      const new_arr = getters.game_players[side].filter((_, i) => i !== index)
      commit('SET_SIDE_PLAYERS', { side, players: new_arr })
    },
    savePlayers({ getters, commit }, { league_id, league_game_id }) {
      commit('SET_SAVING_PLAYERS', true)
      return new Promise((resolve, reject) => {
        a.post(`/league/${league_id}/game/${league_game_id}/game-player/batch`, { game_players: getters.game_players_concatenated })
          .then(e => {
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
          .finally(() => {
            commit('SET_SAVING_PLAYERS', false)
          })
      })
    },
    initGamePlayers({ commit }, { league_game_id, home_club_id }) {
      a(`/league/game/${league_game_id}/game-player`)
        .then(e => {
          const gp = {
            home: e.data.filter(p => p.league_club_id === home_club_id).sort((a, b) => a.num - b.num).map(p =>{ return { ...p, found: true} }),
            away: e.data.filter(p => p.league_club_id !== home_club_id).sort((a, b) => a.num - b.num).map(p =>{ return { ...p, found: true} })
          }

          commit('SET_GAME_PLAYERS', gp)
        })
    },
    checkPlayerExists({ commit }, { name, side }) {
      a(`/admin/player-check?player_name=${name}`)
        .then(e => {
          commit('UPDATE_PLAYER_FOUND', { name, found: e.data.found, side })
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  getters: {
    game_players: state => state.game_players,
    saving_players: state => state.saving_players,
    game_players_concatenated: state => {
      return state.game_players.home.concat(state.game_players.away)
    },
    players_by_club_id: state => id => {
      if(!id) return []
      return state.game_players.filter(p =>{
        return p.league_club_id === id
      })
    }
  }
}

export default game