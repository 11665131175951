<template>
  <v-menu transition="scroll-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        :loading="loading"
      >
        {{ currentTeam ? isMobile && shortenMobile ? currentTeam.team_name[0] : currentTeam.team_name : '' }}
        <v-icon class="ml-3" v-if="userTeams.length > 1">mdi-arrow-down</v-icon>
      </v-btn>
    </template>
    <v-list style="max-height: 90vh; overflow-y: scroll;">
      <v-subheader style="height: 20px; margin-bottom: 10px; justify-content: center;">CHANGE TEAM</v-subheader>
      <v-list-item
        v-for="team in userTeams"
        :key="team.id"
        link
        @click="fetchNewToken(team.id)"
      >
        <v-icon v-if="currentTeam.id == team.id">mdi-circle-small</v-icon>
        <v-list-item-title v-text="team.team_name"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    'shortenMobile': {
      default: true
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    ...mapActions('user', [
      'changeCurrentTeamId',
      'logout',
      'initUser'
    ]),
    ...mapActions('videos', [
      'initVideos'
    ]),
    ...mapActions('noti', [
      'error',
      'info'
    ]),
    ...mapActions('auth', [
      'refetchToken'
    ]),
    fetchNewToken(id) {
      if(id == this.currentTeam.id) return
      this.loading = true
      this.refetchToken(id)
        .then(e => {
          this.initUser(e.data.token)
          this.$nextTick(() => {
            this.$router.push('/videos')
            this.info(this.$t('team.changed_team') + '!')
            this.initVideos(this.currentTeamId)
          })
        })
        .catch(e => this.error(e))
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters('user', [
      'currentTeam',
      'userTeams',
      'loggedIn',
      'currentTeamId'
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    }
  }
}
</script>