import a from '@/utils/axios/axios'

const state = () => ({
  tag_views: [],
  tag_groups: [],
  tags: [],
  opened_tag_view_id: null,
  loading_tag_views: false,
  league_id: null,
  size: 'md',
  mode: 'edit'
})

const auth = {
  namespaced: true,
  state,
  mutations: {
    SET_TAG_VIEWS(state, views) {
      state.tag_views = views
    },
    SET_TAG_GROUPS(state, groups) {
      state.tag_groups = groups
    },
    SET_TAGS(state, tags) {
      state.tags = tags
    },
    SET_LOADING_TAG_VIEWS(state, val) {
      state.loading_tag_views = val
    },
    SET_OPENED_TAG_VIEW_ID(state, id) {
      state.opened_tag_view_id = id
    },
    ADD_TAG_VIEW(state, view) {
      state.tag_views = [...state.tag_views].concat(view)
    },
    ADD_TAG_GROUP(state, group) {
      state.tag_groups = [...state.tag_groups].concat(group)
    },
    UPDATE_TAG_VIEW(state, updatedTagView) {
      let mod = [...state.tag_views];
      const index = mod.findIndex(tagView => tagView.id === updatedTagView.id);
      mod[index] = { ...mod[index], ...updatedTagView };
      state.tag_views = mod;
    },
    REMOVE_TAG_VIEW(state, id) {
      state.tag_views = state.tag_views.filter(tagView => tagView.id !== id);
    },
    REMOVE_GROUP(state, id) {
      state.tag_groups = state.tag_groups.filter(tagView => tagView.id !== id);
    },
    REMOVE_TAG(state, id) {
      state.tags = [...state.tags].filter(tag => tag.id !== id)
    },
    UPDATE_TAG_GROUP(state, updatedTagGroup) {
      let mod = [...state.tag_groups];
      const index = mod.findIndex(tagGroup => tagGroup.id === updatedTagGroup.id);
      mod[index] = { ...mod[index], ...updatedTagGroup };
      state.tag_groups = mod;
    },
    UPDATE_TAG(state, updatedTag) {
      let mod = [...state.tags];
      const index = mod.findIndex(tagGroup => tagGroup.id === updatedTag.id);
      mod[index] = { ...mod[index], ...updatedTag };
      state.tags = mod;
    },
    SET_GROUP_TAGS(state, { tags, group_id }) {
      state.tags = [...state.tags].filter(t => t.group_id !== group_id)
      state.tags = [...state.tags].concat(tags)
    },
    SET_VIEW_GROUPS(state, { groups, view_id }) {
      state.tag_groups = [...state.tag_groups].filter(g => g.tag_view_id !== view_id)
      state.tag_groups = [...state.tag_groups].concat(groups)
    },
    ADD_TAG(state, tag) {
      state.tags = [...state.tags].concat(tag)
    },
    SET_LEAGUE_ID(state, league_id) {
      state.league_id = league_id
    },
    SET_MODE(state, mode) {
      state.mode = mode
    },
    SET_SIZE(state, size) {
      state.size = size
    }
  },
  actions: {
    initTagViews({ rootGetters, commit }, { league_id }) {
      commit('SET_LOADING_TAG_VIEWS', true)

      commit('SET_TAG_VIEWS', [])
      commit('SET_TAG_GROUPS', [])
      commit('SET_TAGS', [])

      return new Promise((resolve, reject) => {
        const team_id = rootGetters['user/currentTeamId']

        const url = league_id ? `/tag-view/league/${league_id}` : `/tag-view/team/${team_id}`
        a(url)
          .then(e => {
            commit('SET_TAG_VIEWS', e.data.tag_views)
            commit('SET_TAG_GROUPS', e.data.tag_groups)
            commit('SET_TAGS', e.data.tags)
            commit('SET_OPENED_TAG_VIEW_ID', e.data.tag_views[0].id)

            resolve(e)
          })
          .catch(e => {
            reject(e)
          })
          .finally(() => {
            commit('SET_LOADING_TAG_VIEWS', false)
          })
      })
    },
    createTagView({ rootGetters, getters, commit }, { tag_view_name }) {
      return new Promise((resolve, reject) => {
        const team_id = rootGetters['user/currentTeamId']
        const league_id = getters.league_id

        const tag_view = {
          tag_view_name,
          team_id: league_id ? null : team_id,
          position: getters.tag_views.length,
          league_id
        }

        a.post('/tag-view/', tag_view)
          .then(e => {
            resolve(e.data)
            commit('ADD_TAG_VIEW', e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    addTagGroup({ commit }, { group_name, team_id, mirrors, league_id, one_tag_only, sport_id, immutable, buffer_start, buffer_end, action_type, enduring, tag_view_id }) {
      return new Promise((resolve, reject) => {
        a.post(`/tag-view/${tag_view_id}/group`, { group_name, team_id, mirrors, league_id, one_tag_only, sport_id, immutable, buffer_start, buffer_end, action_type, enduring, tag_view_id })
          .then(res => {
            commit('ADD_TAG_GROUP', res.data)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    openTagView({ commit }, id) {
      commit('SET_OPENED_TAG_VIEW_ID', id)
    },
    reorderViews({ commit, rootGetters, getters }, views) {
      commit('SET_TAG_VIEWS', views)

      return new Promise((resolve, reject) => {
        const team_id = rootGetters['user/currentTeamId']
        const league_id = getters.league_id

        views = views.map((v, i) => { return { ...v, position: i }})

        a.patch('/tag-view/order/batch', { league_id, team_id, tag_views: views })
          .then(e => {
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    reorderTags({ commit }, { tags, group_id }) {
      commit('SET_GROUP_TAGS', { tags, group_id })

      return new Promise((resolve, reject) => {
        tags = tags.map((t, i) => { return { ...t, position: i }})

        a.patch('/tag-view/tag/order/batch', { group_id, tags })
          .then(e => {
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    reorderGroups({ commit }, { groups, view_id }) {
      commit('SET_VIEW_GROUPS', { groups, view_id })

      return new Promise((resolve, reject) => {
        groups = groups.map((g, i) => { return { ...g, position: i } })

        a.patch(`/tag-view/${view_id}/group/order/batch`, { groups })
          .then(e => {
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    setEditingTag({ commit, getters }, { tag_id, val }) {
      const tag = getters.tag_by_id(tag_id)
      commit('UPDATE_TAG', { ...tag, editing: val })
    },
    updateTagView({ commit }, { id, position, tag_view_name }) {
      return new Promise((resolve, reject) => {
        a.patch(`/tag-view/${id}`, { position, tag_view_name })
          .then(res => {
            // Commit the mutation to update the Vuex store
            commit('UPDATE_TAG_VIEW', res.data);

            // Resolve the promise with the response
            resolve(res);
          })
          .catch(err => {
            // Reject the promise with the error
            reject(err);
          });
      });
    },
    deleteTagView({ commit, getters }, id) {
      return new Promise((resolve, reject) => {
        a.delete(`/tag-view/${id}`)
          .then(res => {
            commit('REMOVE_TAG_VIEW', id);
            if(getters.tag_views.length) commit('SET_OPENED_TAG_VIEW_ID', getters.tag_views[0]?.id)
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    updateTagGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        a.put(`/tag-view/tag-group/${payload.id}`, payload)
          .then(e => {
            commit('UPDATE_TAG_GROUP', e.data)
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    updateTag({ commit }, payload) {
      return new Promise((resolve, reject) => {
        a.put(`/tag-view/tag/${payload.id}`, payload)
          .then(e => {
            commit('UPDATE_TAG', e.data)
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    updateTagHotkey({ commit, getters }, { tag_id, hotkey, shift }) {
      if(!tag_id) return
      const tag = getters.tag_by_id(tag_id)
      commit('UPDATE_TAG', { ...tag, hotkey, shift })
    },
    createTag({ commit, getters }, { tag_name, map_shape, map_color, hotkey, group_id, shift }) {
      return new Promise((resolve, reject) => {
        const position = getters.group_tags(group_id).length
        a.post(`/tag-view/group/${group_id}/tag`, { tag_name, map_shape, map_color, hotkey, position, group_id, shift })
          .then(e => {
            resolve(e.data)
            commit('ADD_TAG', e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    deleteTagGroup({ commit }, id) {
      return new Promise((resolve, reject) => {
        a.delete(`/tag-view/tag-group/${id}`)
          .then(e => {
            commit('REMOVE_GROUP', id)
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    deleteTag({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('REMOVE_TAG', id)

        a.delete(`/tag-view/tag/${id}`)
          .then(e => {
            resolve(e.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  },
  getters: {
    tag_views: state => state.tag_views,
    tag_groups: state => state.tag_groups,
    tags: state => state.tags,
    opened_tag_view_id: state => state.opened_tag_view_id,
    loading_tag_views: state => state.loading_tag_views,
    league_id: state => state.league_id,
    size: state => state.size,
    mode: state => state.mode,
    current_groups: getters => {
      return getters.tag_groups.filter(g => g.tag_view_id === getters.opened_tag_view_id)
    },
    groups_by_view_id: (_, getters) => id => {
      return getters.tag_groups.filter(g => g.tag_view_id === id)
    },
    group_by_tag_id: (_, getters) => id => {
      const tag = getters.tag_by_id(id)
      if(!tag) return null

      return getters.tag_groups.find(g => g.id === tag.group_id)
    },
    group_tags: getters => group_id => {
      if(!getters.tag_groups || !getters.tag_groups.length) return []
      return getters.tags.filter(t => t.group_id === group_id)
    },
    tag_by_id: (_, getters) => tag_id => {
      return getters.tags.find(t => t.id === tag_id)
    }
  }
}

export default auth