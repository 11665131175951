import router from "../../router/index.js";
import a from "@/utils/axios/axios";
import Vue from "vue";

const presentation = {
  namespaced: true,
  state: () => ({
    rawData: null,
    folders: [],
    headFolder: null,
    loading: false,
    initLoading: false,
    activeItemId: null,
    prevId: null,
    textId: null,
    map: null,
    points: null,
    options: {
      source: null,
      poster: null,
      starttime: null,
      endtime: null,
      buffer: null,
    },
    timeDuration: null,
    player_key: 0,
    playingClip: false,
    sideBarHidden: false,
    queue: 0,
    filterOpen: false,
  }),
  mutations: {
    CLEAR(state) {
      state.timeDuration = null;
    },
    HEAD_FOLDER(state, payload) {
      state.headFolder = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_INIT_LOADING(state, payload) {
      state.initLoading = payload;
    },
    UPDATE_CLIP(state, data) {
      state.rawData = data;
      state.options.source = data.mp4_url || data.video_url;
      state.options.mimeType =
        data.video_type == "hls" ? "application/x-mpegURL" : "video/mp4";
      state.options.poster = data.thumb_url;
      state.options.starttime = data.starttime;
      state.options.endtime = data.endtime;
    },
    SET_TIME(state, payload) {
      state.timeDuration = payload;
    },
    UPDATE_MAP(state, payload) {
      state.map = payload;
      a.get(`/maps/${payload.id}/points`)
      .then(e => state.points = e.data)
      .catch(e => this.error(e))
    },
    UPDATE_TEXT(state, e) {
      state.textId = e.id;
    },
    SET_ACTIVE_ITEM_ID(state, payload) {
      state.activeItemId = payload;
    },
    STORE_PREV_ID(state, payload) {
      state.prevId = payload;
    },
    PLAYER_KEY(state) {
      state.player_key++;
    },
    RESET_PRESENTATION(state) {
      state.rawData = null;
      state.folders = [];
      state.headFolder = null;
      state.loading = false;
      state.initLoading = false;
      state.activeItemId = null;
      state.prevId = null;
      state.activeItemIndex = 0;
      state.textId = null;
      state.map = null;
      state.points = null;
      (state.options = {
        source: null,
        poster: null,
        starttime: null,
        endtime: null,
        buffer: null,
      }),
      state.timeDuration = null;
      state.player_key = 0;
    },
    TOGGLE_SIDEBAR(state) {
      state.sideBarHidden = !state.sideBarHidden;
    },
    SET_FOLDERS(state, payload) {
      state.folders = payload;
    },
    SET_FILTER_OPEN(state, payload) {
      state.filterOpen = payload
    },
    QUEUE(state, payload) {
      state.queue = state.queue + payload;
    }
  },
  actions: {
    setFolders({ commit }, payload) {
      commit("SET_FOLDERS", payload);
      commit("CLEAR");
    },

    switchItem({ getters, dispatch, commit }, payload) {
      let direction = payload;
      const folders = getters.getFolders;
      const currentFolder = folders.findIndex((folder) => folder.id === getters.activeItemId)

      if(payload == 'filter') {
        if (currentFolder - folders.findIndex((folder) => folder.id === getters.prevId) < 0) direction = -1
        else direction = 1
      }
      let nextFolder = folders[ currentFolder + direction ];

      if (!nextFolder) {
        if(direction == 1) {
          // viimeinen itemi, mennään alkuun
          nextFolder = folders[0]
        }
        else if(direction == -1) {
          // Ekasta itemistä taakse, mennään loppuun
          nextFolder = folders[folders.length - 1]
        }
      }

      if(nextFolder.type == 'filter') commit('STORE_PREV_ID', getters.activeItemId);

      commit("SET_ACTIVE_ITEM_ID", nextFolder.id);
      dispatch("handleInstance", { folder: nextFolder });

      commit("PLAYER_KEY");
    },

    /**
     * Toggles the presentationSideBar hidden or seen.
     *
     * @param {*} commit vuex context
     */
    toggleSideBar({ commit }) {
      commit("TOGGLE_SIDEBAR");
    },

    /**
     * The function navigates the user to presentation view with the provided openedFolderId
     *
     * @param {Object} context.rootGetters The rootGetters object to access Vuex getters.
     * @param {*} payload should use 'openedFolderId', used to retrieve the correct folder to open for presentation.
     * @returns {void} No return value; function opens the presentation view with a new route.
     * @throws {[Error]} Logs an error and return if the 'payload' is falsy.
     */
    async openPresentation({ dispatch, commit }, payload) {
      let id = payload.id;
      let event = payload.query;

      commit("SET_INIT_LOADING", true);

      if (!id) console.error("No folder id found in route params!");
      await dispatch("folder/getAllFolders", id, { root: true })
        .then((e) => {
          let opened = e.data.filter((f) => f.id == id);
          commit("HEAD_FOLDER", opened[0]);

          let children = e.data.filter(f => {
            if(id == 'root') return !f.parent && f.type !== "folder"
            else return f.parent == id && f.type !== "folder"
          });

          if (children.length < 1) return;

          commit("SET_FOLDERS", children);

          let folderToOpen = children[0];

          if (!folderToOpen) {
            console.error("No valid first child found for:", id);
            return;
          }
          let test = children.find((item) => {
            return (
              item.clip_id == event ||
              item.map_id == event ||
              item.time_id == event ||
              item.text_file_id == event ||
              item.filter_id == event
            );
          });
          if (test) folderToOpen = test;


          dispatch("handleInstance", { folder: folderToOpen });
          // switch (folderToOpen.type) {
          //   case "time":
          //     query.event_id = folderToOpen.time_id;
          //     break;
          //   case "clip":
          //     query.event_id = folderToOpen.clip_id;
          //     break;
          //   case "filter":
          //     query.event_id = folderToOpen.filter_id;
          //     break;
          //   case "map":
          //     query.event_id = folderToOpen.map_id;
          //     break;
          //   case "text":
          //     query.event_id = folderToOpen.text_file_id;
          //     break;
          //   default:
          //     break;
          // }

          // router.replace({
          //   path: "/presentation/" + id,
          //   query,
          // });
          commit("PLAYER_KEY");
          commit("SET_ACTIVE_ITEM_ID", folderToOpen.id);
        })
        .catch((e) => {
          dispatch("noti/error", e, { root: true });
          console.error(e);
        })
        .finally(() => {
          commit("SET_INIT_LOADING", false);
        });
    },
    /**
     * Handles the instance from sideBarItem
     *
     * @param {Object} context Vuex context
     * @param {Object} payload The clicked folderInstance, and the new query parameters
     */
    handleInstance({ dispatch, commit }, { folder }) {
      dispatch("draw/removePauseTimeout", {}, {root: true})
      commit("CLEAR");
      Vue.nextTick(() => {
        commit("SET_ACTIVE_ITEM_ID", folder.id);
        commit("PLAYER_KEY");
      });

      switch (folder.type) {
        case "folder":
          break;
        case "time":
          dispatch("loadTime", folder.time_id);
          dispatch("timeQuery", { folder });
          break;
        case "clip":
          dispatch("loadClip", folder.clip_id);
          dispatch("clipQuery", { folder });
          break;
        case "filter":
          dispatch("filterQuery", folder);
          break;
        case "map":
          dispatch("loadMap", folder.map_id);
          dispatch("mapQuery", { folder });
          break;
        case "text_file":
          dispatch("loadTextFile", folder.text_file_id);
          dispatch("textQuery", { folder });
          break;
      }

      if(folder.type == 'clip') {
        dispatch("clip/clipById", folder.clip_id, { root: true })
          .then((e) => {
            dispatch("comment/setComment", e.data.description, {root: true});
          })
        }
      },

    /**
     * set query parameters in the instance of a filter
     *
     * @param {Object} context Vuex context
     * @param {Object} payload folder properties
     */
    filterQuery(_, folder) {
      router.replace({
        query: {
          event_id: folder.filter_id,
        },
      });
    },

    /**
     * set query parameters in the instance of a time
     *
     * @param {Object} context Vuex context
     * @param {Object} payload folder and query properties
     */
    timeQuery(_, { folder, query }) {
      router.replace({
        query: {
          ...query,
          // folder_id: folder.id,
          event_id: folder.time_id,
        },
      });
    },

    /**
     * set query parameters in the instance of a clip
     *
     * @param {Object} context Vuex context
     * @param {Object} payload folder and query properties
     */
    clipQuery(_, { folder, query }) {
      router.replace({
        query: {
          ...query,
          // folder_id: folder.id,
          event_id: folder.clip_id,
        },
      });
    },

    /**
     * set query parameters in the instance of a map
     *
     * @param {Object} context Vuex context
     * @param {Object} payload folder and query properties
     */
    mapQuery(_, { folder, query }) {
      router.replace({
        query: {
          ...query,
          // folder_id: folder.id,
          event_id: folder.map_id,
        },
      });
    },
    /**
     * set query parameters in the instance of a text
     *
     * @param {Object} context Vuex context
     * @param {Object} payload folder and query properties
     */
    textQuery(_, { folder, query }) {
      router.replace({
        query: {
          ...query,
          // folder_id: folder.id,
          event_id: folder.text_file_id,
        },
      });
    },

    /**
     * Loads time clip with the provided clip_id
     *
     * @function
     * @name loadTime
     * @param {string|number} timeId - The id of the time to be loaded.
     * @throws {Error} Logs an error if the time information could not be retrieved.
     * @returns {void} No return value; updates component.
     *
     */
    loadTime({ commit, dispatch, getters }, payload) {
      commit("QUEUE", 1)
      commit("SET_LOADING", true);

      dispatch("time/timeById", payload, { root: true })
        .then((e) => {
          const data = e.data?.data;
          let first;
          let last;
          data.forEach((d) => {
            if (!first || d.video_time < first) first = d.video_time - 1;
            if (!last || d.video_time > last) last = d.video_time + 1;
          });
          e.data.starttime = first;
          e.data.endtime = last;
            commit("UPDATE_CLIP", e.data);
            commit("draw/SET_CLIP", e.data, {root: true})
            commit("draw/SET_LAYERS", e.data.graphics?.layers || [], {
              root: true,
            });
            commit("draw/SET_OBJECTS", e.data.graphics?.objects || [], {
              root: true,
            });
            commit(
              "draw/SET_ORIGINAL_VIDEO_WIDTH",
              e.data.graphics?.original_video_width || null,
              { root: true }
            );
            commit("draw/SET_OPTIONS", e.data || [], { root: true });
            commit("SET_TIME", e.data.total_in_seconds ? e.data.total_in_seconds.toFixed(2) : null);
            

        })
        .catch((e) => {
          dispatch("noti/error", e, { root: true });
          console.error(e);
        })
        .finally(() => {
          commit("QUEUE", -1)
          if(getters.queue == 0) commit("SET_LOADING", false);
        })
        ;
    },

    /**
     * Loads video clip with the provided clip_id
     *
     * @function
     * @name loadClip
     * @param {string|number} clipId - The id of the clip to be loaded.
     * @throws {Error} Logs an error if the clip information could not be retrieved.
     * @returns {void} No return value; updates component.
     *
     */
    loadClip({ commit, dispatch, getters }, payload) {
      commit("QUEUE", 1)
      commit("SET_LOADING", true);

      dispatch("clip/clipById", payload, { root: true })
        .then((e) => {
          if(!e.data.starttime) {
              e.data.starttime = 0;
             e.data.duration = e.data.endtime-e.data.starttime
          }
          if(!e.data.endtime) {
            e.data.endtime = e.data.duration - 0.53
            e.data.duration = e.data.endtime-e.data.starttime
          }
          commit("UPDATE_CLIP", e.data);
          commit("draw/SET_CLIP", e.data, {root: true})
          commit("draw/SET_LAYERS", e.data.graphics?.layers || [], {
            root: true,
          });
          commit("draw/SET_OBJECTS", e.data.graphics?.objects || [], {
            root: true,
          });
          commit(
            "draw/SET_ORIGINAL_VIDEO_WIDTH",
            e.data.graphics?.original_video_width || null,
            { root: true }
          );
          commit("draw/SET_OPTIONS", e.data || [], { root: true });
          commit("PLAYER_KEY");
        })
        .catch((e) => {
          dispatch("noti/error", e, { root: true });
        })
        .finally(() => {
          commit("QUEUE", -1)
          if(getters.queue == 0) commit("SET_LOADING", false);
        });
    },

    /**
     * Loads map with the provided map_id
     *
     * @function
     * @name loadMap
     * @param {string|number} payload - The id of the map to be loaded.
     * @throws {Error} Logs an error if the map information could not be retrieved.
     * @returns {void} No return value; updates component.
     *
     */
    loadMap({ commit, dispatch, getters }, payload) {
      commit("QUEUE", 1)
      commit("SET_LOADING", true);
      dispatch("map/getMap", { id: payload }, { root: true })
        .then((e) => {
          commit("UPDATE_MAP", e);
        })
        .catch((e) => {
          dispatch("noti/error", e, { root: true });
        })
        .finally(() => {
          commit("QUEUE", -1)
          if(getters.queue == 0) commit("SET_LOADING", false);
        });
    },

    /**
     * Loads textFile with the provided text_file_id
     *
     * @function
     * @name loadTextFile
     * @param {string|number} payload - The id of the textFile to be loaded.
     * @throws {Error} Logs an error if the textFile information could not be retrieved.
     * @returns {void} No return value; updates component.
     *
     */
    loadTextFile({ commit, dispatch, getters }, payload) {
      commit("QUEUE", 1)
      commit("SET_LOADING", true);
      dispatch("text_file/getFile", { id: payload }, { root: true })
        .then((e) => {
          commit("UPDATE_TEXT", e);
        })
        .catch((e) => {
          dispatch("noti/error", e, { root: true });
        })
        .finally(() => {
          commit("QUEUE", -1)
          if(getters.queue == 0) commit("SET_LOADING", false);
        });
    },

    /**
    * Opens filter dialog
    *
    * @function
    * @name openFilter
    * @returns {void} No return value; updates component.
    *
    */
    openFilter({commit}) {
      commit('SET_FILTER_OPEN', true)
    },

    /**
    * Closes filter dialog
    *
    * @function
    * @name closeFilter
    * @returns {void} No return value; updates component.
    *
    */
    closeFilter({commit}) {
      commit('SET_FILTER_OPEN', false)
    },

    /**
     * Returns to library and clears presentation data
     *
     * @function
     * @name closePresentation
     * @returns {void} No return value; updates component.
     *
     */
    closePresentation({ commit, dispatch }) {
      router.push("/library");
      commit("RESET_PRESENTATION");
      dispatch("comment/resetComment", {},{root: true})
    },
  },
  getters: {
    preseRawData: (state) => state.rawData,
    options: (state) => state.options,
    loading: (state) => state.loading,
    initLoading: (state) => state.initLoading,
    player_key: (state) => state.player_key,
    activeItemId: (state) => state.activeItemId,
    prevId: (state) => state.prevId,
    activeItem: (_, getters) => {
      return getters.getFolders?.find((f) => f.id == getters.activeItemId);
    },
    activeType: (_, getters) => getters.activeItem?.type,
    map: (state) => state.map,
    points: (state) => state.points,
    headFolder: (state) => state.headFolder,
    headFolderName: (_, getters) => {
      if(getters.headFolder) return getters.headFolder.name
      return null;
    },
    textId: (state) => state.textId,
    timeDuration: (state) => state.timeDuration,
    sideBarHidden: (state) => state.sideBarHidden,
    getFolders: (state) => state.folders,
    queue: (state) => state.queue,
    filterOpen: (state) => state.filterOpen
  },
};

export default presentation;