import a from '@/utils/axios/axios'

const state = () => ({
  hotkeys: [],
  loading_hotkeys: false
})

const auth = {
  namespaced: true,
  state,
  mutations: {
    SET_HOTKEYS(state, hotkeys) {
      state.hotkeys = hotkeys
    },
    SET_LOADING_HOTKEYS(state, loading) {
      state.loading_hotkeys = loading
    },
    UPDATE_HOTKEY(state, hotkey) {
      const index = state.hotkeys.findIndex(h => h.id === hotkey.id)
      if (index !== -1) {
        state.hotkeys.splice(index, 1, hotkey)
      }
    },
    REMOVE_HOTKEY(state, hotkey_id) {
      const index = state.hotkeys.findIndex(h => h.id === hotkey_id)
      if (index !== -1) {
        state.hotkeys.splice(index, 1)
      }
    }
  },
  actions: {
    updateHotkey({ commit, dispatch }, hotkey) {
      return new Promise((resolve, reject) => {
        a.put(`/hotkey/${hotkey.id}`, hotkey)
          .then(res => {
            commit('UPDATE_HOTKEY', res.data)
            dispatch('tag_view/updateTagHotkey', res.data, { root: true })
            resolve(res.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    getTeamHotkeys({ commit, rootGetters }) {
      commit('SET_LOADING_HOTKEYS', true)
      const team_id = rootGetters['user/currentTeamId']
      return new Promise((resolve, reject) => {
        a(`/hotkey/team/${team_id}`)
          .then(res => {
            commit('SET_HOTKEYS', res.data)
            resolve(res.data)
          })
          .catch(e => {
            reject(e)
          })
          .finally(() => {
            commit('SET_LOADING_HOTKEYS', false)
          })
      })
    },
    removeHotkey({ commit, dispatch }, { hotkey_id, tag_id }) {
      return new Promise((resolve, reject) => {
        a.delete(`/hotkey/${hotkey_id}`)
          .then(res => {
            commit('REMOVE_HOTKEY', hotkey_id)
            dispatch('tag_view/updateTagHotkey', { hotkey: null, shift: null, tag_id }, { root: true })
            resolve(res.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  },
  getters: {
    hotkeys: state => state.hotkeys,
    loading_hotkeys: state => state.loading_hotkeys
  }
}

export default auth